import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Widget as TypeFormWidget } from "@typeform/embed-react";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo"
import PortfolioFeed from "../components/portfolio-feed";

import tshirtsBackgroundImage from "../images/backrounds/729-shirts.jpg";
import smallShirtIcon from "../images/how-to-customize-zendesk/t-small.png";
import mediumShirtIcon from "../images/how-to-customize-zendesk/t-medium.png";
import largeShirtIcon from "../images/how-to-customize-zendesk/t-large.png";
import WebDesignIcon from "../icons/website-design.svg";
import UiUxDesignIcon from "../icons/ui-ux-setup.svg";
import BrandIdentityIcon from "../icons/brand-identity.svg";

const Index = ({location}) => {
  const breakpoints = useBreakpoint();

  const tshirtIconStyles = () => {
    const style = {
      position: "absolute",
      top: "-62px",
      left: "50%",
      transform: "translateX(-50%)",
    };

    const lgStyle = {
      ...style,
      top: "10%",
      left: "-6%",
      transform: "translateX(0)",
    };

    return breakpoints.lg ? lgStyle : style;
  };

  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="How to Customize Zendesk Guide"
        description="One-size doesn&#039;t fit all when it comes to your Zendesk help center customization needs. Take our quiz to find out how to customize Zendesk Guide for customer service success."
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${tshirtsBackgroundImage})`,
            height: "30vw",
            minHeight: "140px",
          }}
        ></div>
      </section>
      <section className="p-b-6">
        <div className="container pb-6">
          <div className="row">
            <div className="col-12">
              <div
                className="card custom-cards card-border p-5 text-left border-bottom-primary"
                style={{ marginTop: "-10vw" }}
              >
                <h2>
                  Zendesk Guide Customization: What Option Is Right for You?
                </h2>
                <p>
                  One-size doesn’t fit all when it comes to your business’s
                  customer support needs. Zendesk Guide is a great way to allow
                  customers to help themselves to support articles and get the
                  answers to the questions they have while taking the burden off
                  of your support team. But what is the right level of
                  customization to fit your needs? The size of your business,
                  products and services, business model, and goals all play a
                  role in determining what your company requires in terms of
                  customer support and deflection.
                </p>
                <p>
                  Read further to understand the different levels of
                  customization design and project sizes available for Zendesk
                  Guide. Don’t forget to{" "}
                  <a href="#project-size-quiz">take our quiz</a> to find out
                  which is the best fit for your company!
                </p>
                <p>
                  Then, we’ll show how to transform your business by giving it a
                  fresh, engaging online presence that blows away the
                  competition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="container py-6">
          <div className="row">
            <div className="col-12">
              <h2>Customers’ Expectations for Customer Support</h2>
              <p>
                Exceptional customer service is the difference between a
                customer who will return and share positive reviews with family
                and friends and a former customer happy to share their negative
                experience with everyone they know. Customers expect timely
                responses to their questions and concerns.
              </p>
              <p>
                The type of support expected varies from customer to customer
                and depends on their persona. Some would prefer a traditional
                phone call or email while more tech-savvy individuals will want
                self-service and live chats. It is important to be able to
                provide excellent customer service to all of your buyer
                personas.
              </p>
              <p>
                Zendesk Guide is a powerful management tool that allows you to
                provide state-of-the-art customer support to your customers
                while allowing your customer support team to tackle the
                difficult tickets.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 pt-6">
              <h2>
                Zendesk Guide Allows for Different Levels of Customization
              </h2>
              <div className="row">
                <div className="col-12 col-lg-6 pb-5 pt-sm-0">
                  Zendesk Guide is designed to allow for versatile customization
                  with an understanding that every business will need and
                  benefit from different features. Here at 729, we generally see
                  customer needs for Zendesk Guide fall into 3 levels of
                  complexity. We refer to them as T-Shirt sizes – small, medium
                  and large. We can help you determine what the best fit is for
                  your company right now, while planning for your future
                  business goals.
                </div>
                <div className="col-12 col-lg-6">
                  <div className="row text-center">
                    <div className="col-12 col-sm-4">
                      <Link to="#info-s">
                        <StaticImage
                          src="../images/how-to-customize-zendesk/t-small.png"
                          layout="constrained"
                          height={200}
                        />
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4 pt-5 pt-sm-0">
                      <Link to="#info-m">
                        <StaticImage
                          src="../images/how-to-customize-zendesk/t-medium.png"
                          layout="constrained"
                          height={200}
                        />
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4 pt-5 pt-sm-0">
                      <Link to="#info-l">
                        <StaticImage
                          src="../images/how-to-customize-zendesk/t-large.png"
                          layout="constrained"
                          height={200}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div id="info-s" className="container pt-6">
          <div className="row p-4">
            <div
              className="col-12 bg-gray-100 px-4 py-6"
              style={{ border: "1px solid #4c86a0" }}
            >
              <StaticImage
                src="../images/how-to-customize-zendesk/t-small.png"
                style={{
                  position: "absolute",
                  top: "-62px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                width={124}
              />

              <h3 className="text-primary-alternative">
                YOUR PROJECT T-SHIRT SIZE IS: SMALL!
              </h3>
              <p>
                Small project sizes looking for basic customization capabilities
                for their Zendesk Guide implementation will have their needs met
                with Zendesk’s out-of-the-box theme or a reasonably priced
                custom theme from Zendesk partners.
              </p>
              <p>
                Companies who fall into this category, typically have the
                following characteristics:
              </p>
              <ul className="text-primary">
                <li>
                  <span className="text-dark">
                    Have a low budget of less than $20k
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Receive a low volume of customers and/or customer support
                    requests
                  </span>
                </li>
                <li>
                  <span className="text-dark">Tight timeline</span>
                </li>
                <li>
                  <span className="text-dark">
                    Have a simple and straightforward content structure
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    They need to make only minor changes to the Copenhagen theme
                    in order to adapt it to their needs. These changes could
                    include logo, color, and font.
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Have a goal to increase customer ticket deflection in order
                    to take the stress off of their agents
                  </span>
                </li>
              </ul>
              <p>
                Small project sizes typically use this as a starting point with
                plans to make improvements and enhance customization in the
                future as their company grows and scales. Many businesses in
                this category are either just starting out and have a low budget
                to put towards customer support improvements or need a quick
                solution to an immediate problem.
              </p>
              <p>
                No matter the reason for falling into this category, you can
                rest assured that you can easily build-upon the basics when the
                time comes. Our custom themes offer advanced customization
                abilities versus the out-of-the-box Copenhagen theme.
              </p>
              <CTA
                href="/zendesk-themes"
                className="btn btn-primary-alternative"
                text="Explore Custom Theme Options"
              />
            </div>
          </div>
        </div>
        <div id="info-m" className="container pt-6">
          <div className="row p-4">
            <div
              className="col-12 bg-gray-100 px-4 py-6"
              style={{ border: "1px solid #e47e3d" }}
            >
              <StaticImage
                src="../images/how-to-customize-zendesk/t-medium.png"
                style={{
                  position: "absolute",
                  top: "-62px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                width={124}
              />
              <h3 className="text-secondary">
                YOUR PROJECT T-SHIRT SIZE IS: MEDIUM!
              </h3>
              <p>
                Companies in the Medium size category need a little more than
                basic customizations. Many companies that fit into this project
                size need solutions that integrate seamlessly with their website
                by offering single sign-on. Additional features, such as the
                ability to modify structural elements of the page templates, add
                icons into the design, or even change what viewers see on the
                sidebar of articles means that extensive customizations can be
                made to an existing theme.
              </p>
              <p>
                Companies with medium size project needs have the following
                characteristics:
              </p>
              <ul className="text-primary">
                <li>
                  <span className="text-dark">
                    A modest budget of $20k – 70k
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Robust brand identity requirements and goals
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Moderately complex content requirements
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Limited customer support resources
                  </span>
                </li>
              </ul>
              <p>
                Companies in this category are often ones who are experiencing
                growth and potentially migrating off of another customer support
                tool in order to take advantage of the state-of-the-art features
                of Zendesk Guide.
              </p>
            </div>
          </div>
        </div>
        <div id="info-l" className="container py-6">
          <div className="row p-4">
            <div
              className="col-12 bg-gray-100 px-4 py-6"
              style={{ border: "1px solid #00833e" }}
            >
              <StaticImage
                src="../images/how-to-customize-zendesk/t-large.png"
                style={{
                  position: "absolute",
                  top: "-62px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                width={124}
              />
              <h3 className="text-primary">
                YOUR PROJECT T-SHIRT SIZE IS: LARGE!
              </h3>
              <p>
                Companies that do not fit in either the small or the medium
                category often find that an out-of-the-box or pre-built theme
                solution does not meet their customer support needs. It could be
                that they want to integrate with third-party tools, provide a
                separate UX for different user types or want to modify the
                Zendesk platform for another purpose. This could be for account
                statuses, subscription services, etc.
              </p>
              <p>
                These customers need a custom solution to fit the very unique
                needs of their business. Companies that need a high level of
                customization typically possess the following characteristics:
              </p>
              <ul className="text-primary">
                <li>
                  <span className="text-dark">
                    A very large budget of more than $70k
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Demanding and extensive brand identity requirements
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Highly complex content requirements
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    The need to integrate with various tools and systems for
                    both customer service and internal business processes
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Receive a high volume of customer support requests and/or a
                    high customer volume
                  </span>
                </li>
              </ul>
              <p>
                Companies in this category are often ones who are experiencing
              </p>
              <p>
                <strong>Is this result unexpected??</strong> If your
                requirements are pretty simple overall, but you got this result,
                it’s likely that you checked one or two things that would bump
                you up into this category.{" "}
                <Link to="/contact-us/">Tell us more about your project</Link>{" "}
                and we can help you determine your true needs.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="container py-6">
          <div className="row">
            <div className="col-12">
              <h2>
                Not Sure What T-shirt Size Your Project Is? Take Our Quiz!
              </h2>
              <TypeFormWidget id="dSnejlbE" style={{ height: "500px" }} />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-6">
          <div className="row">
            <div className="col-12">
              <h2>What do your results mean?</h2>
            </div>
          </div>
        </div>
        <div className="container py-6">
          <div className="row px-4">
            <div
              className="col-12 card custom-cards card-border-left text-left px-lg-6 px-4 py-6"
              style={{ borderColor: "#4c86a0" }}
            >
              <img
                src={smallShirtIcon}
                style={tshirtIconStyles()}
                width={124}
                alt="Large t-shirt"
              />
              <h3 className="text-primary-alternative">
                YOUR PROJECT T-SHIRT SIZE IS: SMALL!
              </h3>
              <p>
                Your company can probably get away with the free Copenhagen
                theme, at least for a little while, or invest in a low-cost
                theme from the Marketplace. The Copenhagen theme allows you to
                customize your logo, favicon, brand images, and choose from a
                variety of fonts. Some of the low-cost themes will allow you to
                swap out icons and featured images.
              </p>
              <p>
                If you want a pre-built Zendesk Guide Theme that is different
                from the themes you typically find, which are most often just
                another version of Copenhagen, then check out what 729 Solutions
                has to offer. Our theme layouts are designed to clean up and
                streamline the elements that are most important and move away
                from what users have begun to identify as the ‘standard’ help
                center layout.
              </p>
              <CTA
                href="/zendesk-themes"
                className="btn btn-primary-alternative"
                text="View Our Zendesk Themes"
              />
            </div>
          </div>
        </div>
        <div className="container py-6">
          <div className="row px-4">
            <div
              className="col-12 card custom-cards card-border-left text-left px-lg-6  px-4 py-6"
              style={{ borderColor: "#e47e3d" }}
            >
              <img
                src={mediumShirtIcon}
                style={tshirtIconStyles()}
                width={124}
                alt="Large t-shirt"
              />
              <h3 className="text-secondary">
                YOUR PROJECT T-SHIRT SIZE IS: MEDIUM!
              </h3>
              <p>
                If the scope of your company’s needs fits in this category, you
                will most likely need{" "}
                <a
                  href="https://www.zendesk.com/guide/features/#features"
                  target="_blank"
                  className="text-secondary"
                  n
                  rel="noreferrer"
                >
                  additional features
                </a>{" "}
                such as Chat, Talk, Explore, Answer Bots, Web Widget, and much
                more! You will also want access to the App store to determine if
                any of the OOB integrations will be useful to your business.
              </p>
              <p>
                Enhance your customers’ experience without breaking the bank by
                contacting 729 Solutions to schedule a free consultation with
                one of our specialists to discuss simple yet impactful
                customizations that can be made to the theme of your choice.
              </p>
              <CTA
                href="/contact-us/"
                className="btn btn-secondary"
                text="Schedule A Free Consultation"
              />
            </div>
          </div>
        </div>
        <div className="container py-6">
          <div className="row px-4">
            <div className="col-12 card custom-cards card-border-left text-left px-lg-6  px-4 py-6">
              <img
                src={largeShirtIcon}
                style={tshirtIconStyles()}
                width={124}
                alt="Large t-shirt"
              />
              <h3 className="text-primary">
                YOUR PROJECT T-SHIRT SIZE IS: LARGE!
              </h3>
              <p>
                Your company needs a custom solution with all the bells and
                whistles! More than likely you will want extensive third-party
                integrations with your existing applications, tools, and systems
                as well as implement self-service capabilities for your
                customers’ accounts.
              </p>
              <p>
                A custom Zendesk Guide solution will increase customer
                satisfaction while simultaneously decreasing the workload of
                your customer support team by allowing customers to help
                themselves and allowing agents to resolve the complex issues.
                Let’s talk about customizing a Zendesk Guide theme to fit your
                company’s unique needs.
              </p>
              <p>Reach out today for a free consultation!</p>
              <CTA
                href="/contact-us/"
                className="btn btn-primary"
                text="Schedule A Free Consultation"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark">
        <div className="container px-4 py-6">
          <div className="row">
            <div className="col-12 text-center text-white">
              <h2 className="text-white">
                HOW DO YOU KNOW ITS TIME TO BRING IN THE EXPERTS?
              </h2>
              <p>
                If your team lacks the available time and know-how it’s time to
                save yourself money, time, and the hassle. Bring in the experts!
                When your company’s needs are extensive and complex, you need
                professionals who have the knowledge and experience to
                customize, deploy, and maintain your Zendesk Guide system.
              </p>
              <p>
                729 Solutions is a proud partner of Zendesk with years of
                experience under their belt. Our specialists understand the
                Zendesk code language and can create custom solutions to fit
                your specific needs and requirements. Your goals are achieved
                more quickly because our knowledgeable team can skip over the
                trial and error stage and dive right into delivering your
                company results.
              </p>
              <p>
                We are one of the few Master Zendesk partners with this high of
                a level of training and expertise with over 120 projects in our
                portfolio. Our streamline process of design, development, and
                deployment alongside our experience with integrating third-party
                systems makes us the go-to for all of your Zendesk solutions.
              </p>
              <CTA
                href="/zendesk-services"
                className="btn btn-primary"
                text="View Our Zendesk Services"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="pb-6 pb-md-5">
        <div className="container py-6">
          <div className="row">
            <div className="col-12">
              <PortfolioFeed />
            </div>
            <div className="col-12 text-center pt-6">
              <h3>729 Solutions Web Consulting Services</h3>
              <div className="align-content-center flex-column flex-md-row row">
                <div className="col-12 col-md-4 py-4">
                  <Link to="/web-design-consulting-services/">
                    <WebDesignIcon
                      className="mb-2"
                      color="#76bc21"
                      width={99}
                    />
                    <p className="text-dark">Website Design Consulting</p>
                  </Link>
                </div>
                <div className="col-12 col-md-4 py-4">
                  <Link to="/user-experience-consultant-services/">
                    <UiUxDesignIcon
                      className="mb-2"
                      color="#76bc21"
                      width={99}
                    />
                    <p className="text-dark">UI/UX Design Consulting</p>
                  </Link>
                </div>
                <div className="col-12 col-md-4 py-4">
                  <Link to="/branding-design-logo-design/">
                    <BrandIdentityIcon
                      className="mb-2"
                      color="#76bc21"
                      width={99}
                    />
                    <p className="text-dark">
                      Branding & Identity Design Consulting
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary pt-6 pt-sm-0">
        <div className="container pt-6 pt-sm-0 pb-6">
          <div className="row">
            <div className="col-12 pt-6 pt-sm-0">
              <div
                className="card custom-cards card-border p-5 text-left ml-auto mr-auto"
                style={{
                  marginTop: "calc(15% - 200px)",
                  width: "85vw",
                }}
              >
                <h3>About 729 Solutions</h3>
                <p>
                  729 is a team of hard-working designers, developers, project
                  managers, and solutions architects. We are problem solvers who
                  come up with new ways to make technology faster, safer, and
                  easier. We are builders who create experiences and products
                  that people love. We integrate complex systems in the simplest
                  ways possible. We help fast-growing companies and their
                  customers do more.
                </p>
                <p>
                  We’ve been engineering amazing technology for 15 years and we
                  want to do it for you.
                </p>
                <p>
                  Then, we’ll show how to transform your business by giving it a
                  fresh, engaging online presence that blows away the
                  competition.
                </p>
                <CTA
                  href="/contact-us/"
                  className="btn btn-primary-light"
                  text="Let's Chat About Your Project"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-6 pb-0">
          <div className="row">
            <div className="col-12">
              <BlogFeed title="From the blog" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
